import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ADMIN_API } from "../common/common";
import { postApiAdmin } from "../service";
import { Logout } from "../common/Funcation";

const cookies = new Cookies();
export default function TransactionRecords() {
  const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [isLoadingMain, setIsLoadingMain] = useState(false);
  const [pageData, setPageData] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((e: any) => e.userData);

  const HeaderData = useSelector((e: any) => e.Header);

  const userId = HeaderData?._id;

  const DD = useSelector((e: any) => e.domainDetails);

  useEffect(() => {
    if (userData || cookies.get("skyTokenFront")) {
      getPageData();
    }
    return () => {};
  }, [userData]);

  // useEffect(() => {
  //   if (cookies.get("skyTokenFront")) {
  //     getPageData();
  //   }
  //   return () => {};
  // }, []);

  const getPageData = async (
    FROM: string = "",
    TO: string = "",
    FILTER: any = { reportType: "", report: "" },
    PAGE: string = "1"
  ) => {
    setIsLoadingMain(true);
    let data: any = {
      api: ADMIN_API.MY_ACCOUNT.GET_STATEMENTS,
      value: {
        id: userId ? userId : userData?._id,
        page: PAGE ? PAGE : "1",
        limit: "10",
        type:"deposit"
      },
    };
    //reportType
    //report

    if (TO !== "") {
      data.value.to = TO;
    }
    if (FROM !== "") {
      data.value.from = FROM;
    }
    if (FILTER.reportType !== "") {
      data.value.type = FILTER.reportType;
    }

    await postApiAdmin(data)
      .then(function (response) {
        console.log(response);
        setPageData(response.data.data);
        setIsLoadingMain(false);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          setIsLoadingMain(false);
          Logout();
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="mob-header">
        <div className="back">
          <img
            src="../../images/extra-icon/angle-left-white.png"
            height={22}
            onClick={() => navigate("/")}
          />
        </div>
        <div className="center">
          <h5>Transaction Records</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          <span>Help</span>
        </div>
      </div>

      <div className='balance-deposit-sec'>


{pageData.userStatement && pageData.userStatement?.results?.length > 0 ? pageData.userStatement.results.map((item: any, i: any) => {
    return (
        <table className="rounded overflow-hidden bg-white table table-bordered">
            <thead>
                <tr>
                    <th colSpan={4}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</th>
                </tr>
            </thead>
            <tbody>

                <tr>
                    {item.credit > 0 ?
                        <td width="50%">
                            Credit
                            <strong className="d-block text-green">{item.credit}</strong>
                        </td>
                        :
                        <td width="50%">
                            Debits
                            <strong className="d-block text-danger">{item.debit}</strong>
                        </td>
                    }
                    <td width="50%">
                        Balance
                        <strong className="d-block">{item.balance}</strong>
                    </td>
                </tr>

                <tr>
                    <td width="50%" colSpan={4} style={{ fontSize: "14px" }}>
                        Remark
                        <strong className="d-block">{item.Remark ? item.Remark : '-'}</strong>
                    </td>
                </tr>

                {/* <tr>
                            <td width="40%">From/To</td>
                            <td width="60%">{item.from?.user_name}/{item.to?.user_name}</td>
                        </tr> */}
            </tbody>
        </table>
    )
}) : <h2>No Record</h2>}

</div>

      {/* <div className="panel1">
        <div className="records-timing-box">
          <div className="records7days">
            <span>Today</span>
          </div>
          <div className="filter">
            <img src={"../../images/extra-icon/filter.png"} height="25px" />
          </div>
        </div>
        <div className="records-th">
          <div>Type</div>
          <div>Amount</div>
          <div>Status</div>
          <div>Txn Date</div>
        </div>
        <div className="records-list">
          <div className="empty-records">
            <div>
              <img
                src={"../../images/extra-icon/notebook.png"}
                height="100px"
              />
              <div>No Data</div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
