import React, { useEffect, useState } from "react";
import Menu from "../assets/images/menu-bar.png";
import Logo from "../assets/images/nav-logo.png";
import Comment from "../assets/images/comment.png";
import FooterComponent from "../components/FooterComponent";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import {
  arcadeMenu,
  casinoMenu,
  fishingMenu,
  lotteryMenu,
  slotMenu,
  tableMenu,
  USER_API,
} from "../common/common";
import { useDispatch, useSelector } from "react-redux";
import { getApiWithData, notifyError, postApi } from "../service";
import { getImageUrl, Logout } from "../common/Funcation";
import { isMobile } from "react-device-detect";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function Game() {
  // const dispatch = useDispatch();
  // const CasinoData = useSelector((e: any) => e);
  let { casino, platForm } = useParams<any>();
  const [platforms, setPlatforms] = useState<string[]>([platForm || ""]);
  const [pageData, setPageData] = useState<any>();
  const [flag, setFlag] = useState<boolean>(false);
  console.log("casino :: ", casino);
  console.log("platForm :: ", platForm);

  useEffect(()=>{
    document.documentElement.style.setProperty('--bodyBackGround', `#111111`)
  },[])
  useEffect(()=>{
    handleCasinoChange(casino)
    return(()=>{})
  },[flag])

  const managePlatforms = (name : string) =>{
    setFlag(!flag);
    console.log("managePlatforms : name : ", name);
    console.log("managePlatforms : platforms : ", platforms);
    
    if(platforms.includes(name)){
      const index = platforms.findIndex((_v) => _v === name);

      platforms.splice(index, 1);
      // setPlatforms((e:any)=> {
      //   if(e !== name){
      //     return '';
      //   }
      //   return name
      // })
      setPlatforms(platforms)
    }else{
      platforms.push(name);
      setPlatforms(platforms)
    }
    // setPlatforms(platforms)
  }

  const getCasinoLink = async (item: any) => {
    if (cookies.get("skyTokenFront")) {
      let data = {
        api: USER_API.CASINO_LOGIN,
        value: {
          id: item._id,
          isMobileLogin: isMobile ? true : false,
          domain:
            window.location.hostname === "localhost"
              ? "taka365.win"
              : window.location.hostname,
        },
        token: cookies.get("skyTokenFront") ? cookies.get("skyTokenFront") : "",
      };

      await postApi(data)
        .then(function (response) {
          window.open(response?.data?.data?.url, "_blank");
        })
        .catch((err) => {
          console.log(err);
          notifyError(err?.response?.data?.message);

          if (err.response.data.statusCode === 401) {
            Logout();
            // notifyError('Pin unsuccess')
            // navigate('/login')
          }
        });
    } else {
      // if (isMobile) { navigate('/login') }
      if (isMobile) {
        window.location.pathname = "/login";
      }
      notifyError("please login first");
    }
  };

  const handleCasinoChange = async (name: any) => {
    console.log("handleCasinoChange");
    
    let data = {
        api: USER_API.GET_CASINO,
        value: {
            gameType: name,
            filter: "a-z",
            domain: window.location.hostname,
            platform:platforms
        },
    }
    // if (CasinoData[name]) {
    //     if (Object.keys(CasinoData[name])?.length) {
    //         return;
    //     }
    // }
    // else {
        await getApiWithData(data).then(function (response) {
            console.log(response);
            // setCasnioData(response?.data?.data)
            // dispatch({ type: 'SET_GAME_DATA', payload: { type: name, data: response.data.data } })
            setPageData(response.data.data)
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                // Logout()
                // navigate('/login')
            }
        })
    // }

}
// console.log("CasinoData :: ", CasinoData);

  return (
    <>
      <div className="sprt-menu">
        <div className="" id="all-btn">
          <img src="../../images/extra-icon/more.svg" />
        </div>

        <div className="list">
          <div className="list-width" id="list-width">
            {casino === "Casino" &&
              casinoMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
            {casino === "Slot" &&
              slotMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
            {casino === "Table" &&
              tableMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
            {casino === "Fishing" &&
              fishingMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
            {casino === "Arcade" &&
              arcadeMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
            {casino === "Lottery" &&
              lotteryMenu.map((_value) => {
                return (
                  <>
                    <div className={`list-btn ${platforms.includes(_value.platform) ? 'active' : ''}`} onClick={() => managePlatforms(_value.platform)}>
                      <img src={_value.img} alt={_value.title} />
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="search">
        <FontAwesomeIcon icon={faMessage} />
        </div>
      </div>
      <div className="sports-grid">
      {/* {CasinoData[casino || 'Casinow']?.casinoType?.map((catalogItem: any, index: any) => { */}
      {pageData && pageData?.casinoType?.map((catalogItem: any, index: any) => {
          return (
            <div className="item">
              <div className='image' key={index}>
                      <img src={getImageUrl(catalogItem?.image)} onClick={() => getCasinoLink(catalogItem)} alt='' />
              </div>
              <div className="item-footer">{catalogItem?.title}</div>
              </div>
          )
      })}
      </div>
      <FooterComponent />
    </>
  );
}
