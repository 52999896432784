import React from 'react'

export default function Signup() {
    return (
        <>
            <div className="login-title">
                <div className="back">
                    <img src="../../images/extra-icon/angle-left-white.png" height={22} />
                </div>
                <div className="center">
                    <h5>Sign Up</h5>
                </div>
            </div>
            <div className="login-page-container">

                <div className="logo"><img src="../../images/extra-icon/nav-logo.png" /></div>

                <div className="form">
                    <div className="form-line">
                        <div className="form-label">Username</div>
                        <div className="form-input">
                            <input type="text" id="login-username" placeholder="4-15 char, allow number" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Password</div>
                        <div className="form-input">
                            <input type="password" id="login-username" placeholder="password" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Confirm Password</div>
                        <div className="form-input">
                            <input type="password" id="login-username" placeholder="password" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Currency</div>
                        <div className="form-input">
                            <select id="currency">
                                <option>BDT</option>
                                <option>INR</option>
                                <option>PKR</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div className="form-line">
                        <div className="form-label">Full Name</div>
                        <div className="form-input">
                            <input type="text" placeholder="Full Name" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Phone Number</div>
                        <div className="form-input">
                            <input type="password" placeholder="+91 1234567890" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Email</div>
                        <div className="form-input">
                            <input type="password" placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-label">Reffer Code</div>
                        <div className="form-input">
                            <input type="password" placeholder="Reffer Code" />
                        </div>
                    </div>

                </div>
                <div className="form">
                    <div className="form-line">
                        <div className="form-label">Verification Code</div>
                        <div className="form-input">
                            <input id="su-verifycode" type="text" placeholder="Enter 4 digit code" />
                        </div>
                        <div id="code">3586</div>
                    </div>
                    <span id="su-verify-error"></span>
                </div>
             
                <div className="login-btn">
                    <button>Sign Up</button>
                </div>
                <div className="dont-have-acc">
                    <span>Do you already have an account?</span> <span id="login-page-signupbtn">Login</span>
                </div>
            </div>
        </>
    )
}
