export const USER_API = {
  LOGIN: "auth/login",
  CHANGE_PASSWORD: "auth/changePassword",
  HOME: "home/baji",
  IN_PLAY: "inPlay",
  MULTI_LIST: "multi",
  PIN: "multi/pin",
  PLACE_BET: "sports/placeBet",
  BET_LIST: "sports/betList",
  SEARCH: "sports/search",
  GET_CHANNEL_ID: "sports/getChannelId",
  GET_STACK: "getStack",
  UPDATE_STACK: "updateStack",
  GET_BLOCK: "sports/getBlockMatch",
  GET_PROFILE: "auth/getProfile",
  MARKET_LIST: "home/marketList",
  CASINO_LOGIN: "casino/login",
  ADD_DEPOSIT: "onlinePayment/addDeposit",
  ADD_WITHDRAW: "onlinePayment/addWithdrawal",
  GET_METHOD: "onlinePayment/getMethod",
  GET_WITHDRAWAL_REQUEST: "onlinePayment/getWithdrawalRequest",
  GET_DEPOSIT_REQUEST: "onlinePayment/getDepositRequest",
  GET_CASINO: "home/getCasino/baji",
  GET_PROFIT_LOST: "profitLost",
};

export const ADMIN_API = {
  LOGIN: "login",
  GET_ROLE: "getRole",
  DOWN_LINE_LIST: "downLineList",
  PLAYER_CREATE: "downLineList/player/create",
  PLAYER_GET_PROFILE: "downLineList/player/getProfile",
  PLAYER_BET_HISTORY: "downLineList/player/betHistory",
  PLAYER_CASINO_BET_HISTORY: "downLineList/player/casinoHistory",

  AGENT_CREATE: "downLineList/agent/create",
  AGENT_UPDATE_INFO: "downLineList/agent/updateInfo",
  PLAYER_UPDATE_INFO: "downLineList/player/updateInfo",
  AGENT_GET_LIST: "downLineList/agent/getList",
  BET_HISTORY: "downLineList/player/betHistory",
  REPORT: {
    DOWN_LINE: "report/downline",
    ACCOUNT_STATEMENT: "report/accountStatment",
    STATEMENT_BET_VIEW: "myAccount/statementBetView",
  },
  MY_ACCOUNT: {
    GET_PROFILE: "myAccount/getProfile",
    GET_ACTIVITIES: "myAccount/getActivities",
    GET_STATEMENTS: "myAccount/getStatements",
  },
  BET_LIST: {
    LIST_LIVE: "betList/liveList",
    LIST: "betList/list",
  },
  RISK: "risk",
  BANKING: {
    GET_BALANCE: "banking/master/getBalance",
    ADD_BALANCE: "banking/master/addBalance",
    GET_LIST: "banking/getList",
    UPDATE: "banking/update",
  },
  SPORTS: {
    LIST: "sports/getList",
  },
  SETTING: {
    BANNER: {
      GET_LIST: "setting/banner",
      GET_ONE: "setting/banner/getOne",
      CREATE: "setting/banner/create",
      UPDATE: "setting/banner/update",
    },
    DASHBOARD: {
      GET_LIST: "setting/dashboard",
      GET_ONE: "setting/dashboard/getOne",
      CREATE: "setting/dashboard/create",
      UPDATE: "setting/dashboard/update",
    },
    MANAGE_CASINO: {
      GET_LIST: "setting/manageCasino",
      CREATE: "setting/manageCasino/create",
    },
    PREMIUM_HISTORY: {
      GET_LIST: "setting/premiumHistory",
      LIST_OF_BET: "setting/premiumHistory/listOfBet",
      ROLL_BACK_WINNER: "setting/premiumHistory/rollBackWinner",
    },
    FANCY_HISTORY: {
      GET_LIST: "setting/fancyHistory",
      LIST_OF_BET: "setting/fancyHistory/listOfBet",
      ROLL_BACK_WINNER: "setting/fancyHistory/rollBackWinner",
    },
    MANAGE_PREMIUM: {
      GET_LIST: "setting/managePremium",
      LIST_OF_BET: "setting/managePremium/listOfBet",
      DECLARE_WINNER: "setting/managePremium/declareWinner",
    },
    MANAGE_FANCY: {
      GET_LIST: "setting/manageFancy",
      LIST_OF_BET: "setting/manageFancy/listOfBet",
      DECLARE_WINNER: "setting/manageFancy/declareWinner",
    },
    MATCH_HISTORY: {
      GET_LIST: "setting/matchHistory",
    },
    SPORT_MARKET: {
      GET_LIST: "sports/sportMarket",
      DECLARE_WINNER: "sports/declareWinner",
      UPDATE: "sports/updare",
    },
    WEBSITE: {
      CREATE: "setting/website",
      UPDATE: "setting/website/update",
      GET_SITE: "setting/website/getSite",
      LIST: "setting/website/list",
      GET_DOMAIN: "setting/website/getDomain",
    },
    FILE_UPLOAD: "setting/fileUpload",
  },
};

export const COLOR_OPTION = [
  {
    backgroundColor: "#ffcc2f",
    color: "#222",
    label: "Yellow",
    value: "yellow",
    headerTextColor: "#ffcc2f",
  },
  {
    backgroundColor: "#67f0c2",
    color: "#222",
    label: "Onsen",
    value: "onsen",
    headerTextColor: "#67f0c2",
  },
  {
    backgroundColor: "#291e39",
    color: "#FFF",
    label: "Purple",
    value: "purple",
    headerTextColor: "#291e39",
  },
  {
    backgroundColor: "#DE352C",
    color: "#FFF",
    label: "Red",
    value: "red",
    headerTextColor: "#DE352C",
  },
  {
    backgroundColor: "#5D6699",
    color: "#000",
    label: "Blue",
    value: "blue",
    headerTextColor: "#5D6699",
  },
  {
    backgroundColor: "#000000",
    color: "#FFF",
    label: "Black",
    value: "black",
    headerTextColor: "#000000",
  },
  {
    backgroundColor: "#FF3377",
    color: "#FFF",
    label: "Pink",
    value: "pink",
    headerTextColor: "#FF3377",
  },
  {
    backgroundColor: "#FFFEBB",
    color: "#000",
    label: "Light Yellow",
    value: "light-yellow",
    headerTextColor: "#FFFEBB",
  },
  {
    backgroundColor: "#FF8C00",
    color: "#FFF",
    label: "Dark Orange",
    value: "dark-orange",
    headerTextColor: "#FF8C00",
  },
  {
    backgroundColor: "#FFD700",
    color: "#000",
    label: "Gold",
    value: "gold",
    headerTextColor: "#FFD700",
  },
  {
    backgroundColor: "#0CFE09",
    color: "#313131",
    label: "Radioactive Green",
    value: "radioactive-green",
    headerTextColor: "#0CFE09",
  },
  {
    backgroundColor: "#708090",
    color: "#000",
    label: "Slategrey",
    value: "slategrey",
    headerTextColor: "#708090",
  },
  {
    backgroundColor: "#0D4F8B",
    color: "#FFF",
    label: "Indigo Dye",
    value: "indigo-dye",
    headerTextColor: "#0D4F8B",
  },
  {
    backgroundColor: "#428139",
    color: "#FFF",
    label: "Green",
    value: "green",
    headerTextColor: "#428139",
  },
  {
    backgroundColor: "#00A884",
    color: "#000",
    label: "Persian Green",
    value: "persian-green",
    headerTextColor: "#00A884",
  },
  {
    backgroundColor: "#51e2f5",
    color: "#000",
    label: "Electric Blue",
    value: "electric-blue",
    headerTextColor: "#51e2f5",
  },
  // { backgroundColor: "#0a58ca", color: "#FFF", label: 'Dark Royal Blue', value: 'dark-royal-blue' },
  {
    backgroundColor: "#0047ab",
    color: "#FFF",
    label: "Dark Royal Blue",
    value: "dark-royal-blue",
    headerTextColor: "#6ac2ff",
  },
];

export const casinoMenu = [
  {
    img: "../../images/casinoIcon/evo1.png",
    title: "EVO",
    link: "/game",
    platform: "EVO",
  },
  {
    img: "../../images/casinoIcon/sexy.png",
    title: "SEXY",
    link: "/game",
    platform: "SEXY",
  },
  {
    img: "../../images/casinoIcon/play.png",
    title: "PP",
    link: "/game",
    platform: "PP",
  },
  {
    img: "../../images/casinoIcon/pt.png",
    title: "PT",
    link: "/game",
    platform:"PT"
  },
  {
    img: "../../images/casinoIcon/venus.png",
    title: "VENUS",
    link: "/game",
    platform:"VENUS"
  },
];
export const slotMenu = [
  {
    img: "../../images/casinoIcon/pg1.png",
    title: "PG",
    link: "/game",
    platform: "PG",
  },
  {
    img: "../../images/casinoIcon/jdb.png",
    title: "JDB",
    link: "/game",
    platform: "JDB",
  },
  {
    img: "../../images/casinoIcon/rt1.png",
    title: "RT",
    link: "/game",
    platform: "RT",
  },
  {
    img: "../../images/casinoIcon/sg.png",
    title: "SG",
    link: "/game",
    platform: "SG",
  },
  {
    img: "../../images/casinoIcon/jl.png",
    title: "JILI",
    link: "/game",
    platform: "JILI",
  },
  {
    img: "../../images/casinoIcon/fc1.png",
    title: "FC",
    link: "/game",
    platform: "FC",
  },
  {
    img: "../../images/casinoIcon/play.png",
    title: "PP",
    link: "/game",
    platform: "PP",
  },
  {
    img: "../../images/casinoIcon/ps1.png",
    title: "PLAYSTER",
    link: "/game",
    platform: "PLAYSTER",
  },
  {
    img: "../../images/casinoIcon/fs.png",
    title: "FASTSPIN",
    link: "/game",
    platform: "FASTSPIN",
  },
  {
    img: "../../images/casinoIcon/wm.png",
    title: "WorldMatch",
    link: "/game",
    platform: "WorldMatch",
  },
  {
    img: "../../images/casinoIcon/playngo.png",
    title: "PNG",
    link: "/game",
    platform: "PNG",
  },
  {
    img: "../../images/casinoIcon/play8.png",
    title: "PLAY8",
    link: "/game",
    platform: "PLAY8",
  },
  {
    img: "../../images/casinoIcon/netent.png",
    title: "NETENT",
    link: "/game",
    platform: "NETENT",
  },
  {
    img: "../../images/casinoIcon/pt.png",
    title: "PT",
    link: "/game",
    platform: "PT",
  },
  {
    img: "../../images/casinoIcon/rich88.png",
    title: "RICH88",
    link: "/game",
    platform: "RICH88",
  },
  {
    img: "../../images/casinoIcon/ka.png",
    title: "KA",
    link: "/game",
    platform: "KA",
  },
  {
    img: "../../images/casinoIcon/joker.png",
    title: "JOKER",
    link: "/game",
    platform: "JOKER",
  },
  {
    img: "../../images/casinoIcon/cq9.png",
    title: "CQ9",
    link: "/game",
    platform: "CQ9",
  },
];
export const tableMenu = [
  {
    img: "../../images/casinoIcon/jl.png",
    title: "JILI",
    link: "/game",
    platform: "JILI",
  },
  {
    img: "../../images/casinoIcon/playngo.png",
    title: "PNG",
    link: "/game",
    platform: "PNG",
  },
  {
    img: "../../images/casinoIcon/rich88.png",
    title: "RICH88",
    link: "/game",
    platform: "RICH88",
  },
  {
    img: "../../images/casinoIcon/km.png",
    title: "KM",
    link: "/game",
    platform: "KM",
  },
  {
    img: "../../images/casinoIcon/ka.png",
    title: "KA",
    link: "/game",
    platform: "KA",
  },
  {
    img: "../../images/casinoIcon/wm.png",
    title: "WorldMatch",
    link: "/game",
    platform: "WorldMatch",
  },
  {
    img: "../../images/casinoIcon/cq9.png",
    title: "CQ9",
    link: "/game",
    platform: "CQ9",
  },
  {
    img: "../../images/casinoIcon/joker.png",
    title: "JOKER",
    link: "/game",
    platform: "JOKER",
  },
  {
    img: "../../images/casinoIcon/ludo.png",
    title: "LUDO",
    link: "/game",
    platform: "LUDO",
  },
];
export const fishingMenu = [
  {
    img: "../../images/casinoIcon/jl.png",
    title: "JILI",
    link: "/game",
    platform: "JILI",
  },
  {
    img: "../../images/casinoIcon/fc1.png",
    title: "Fc",
    link: "/game",
    platform: "Fc",
  },
  {
    img: "../../images/casinoIcon/ka.png",
    title: "KA",
    link: "/game",
    platform: "KA",
  },
  {
    img: "../../images/casinoIcon/jdb.png",
    title: "JDB",
    link: "/game",
    platform: "JDB",
  },
  {
    img: "../../images/casinoIcon/ps1.png",
    title: "PLAYSTER",
    link: "/game",
    platform: "PLAYSTER",
  },
  {
    img: "../../images/casinoIcon/cq9.png",
    title: "CQ9",
    link: "/game",
    platform: "CQ9",
  },
  {
    img: "../../images/casinoIcon/joker.png",
    title: "JOKER",
    link: "/game",
    platform: "JOKER",
  },
  {
    img: "../../images/casinoIcon/play8.png",
    title: "PLAY8",
    link: "/game",
    platform: "PLAY8",
  },
];
export const arcadeMenu = [
  {
    img: "../../images/casinoIcon/rich88.png",
    title: "RICH88",
    link: "/game",
    platform: "RICH88",
  },
  {
    img: "../../images/casinoIcon/cq9.png",
    title: "CQ9",
    link: "/game",
    platform: "CQ9",
  },
  {
    img: "../../images/casinoIcon/play.png",
    title: "PP",
    link: "/game",
    platform: "PP",
  },
  {
    img: "../../images/casinoIcon/fc1.png",
    title: "Fc",
    link: "/game",
    platform: "Fc",
  },
  {
    img: "../../images/casinoIcon/ps1.png",
    title: "PLAYSTER",
    link: "/game",
    platform: "PLAYSTER",
  },
  {
    img: "../../images/casinoIcon/ka.png",
    title: "KA",
    link: "/game",
    platform: "KA",
  },
];
export const lotteryMenu = [
  {
    img: "../../images/casinoIcon/number.png",
    title: "NUMBER",
    link: "/game",
    platform: "NUMBER",
  },
];

export const sportMenu = [
  {
    img: "../../images/casinoIcon/EXCHANGE.svg",
    title: "EXCHANGE",
    link: "/cricket",
  },
  {
    img: "../../images/casinoIcon/I-SPORTS.svg",
    title: "I SPORTS",
    link: "/",
  },
  {
    img: "../../images/casinoIcon/SBO.svg",
    title: "SBO",
    link: "/",
  },
  {
    img: "../../images/casinoIcon/BJSPORTS.png",
    title: "BJ SPORTS",
    link: "/",
  },
  {
    img: "../../images/casinoIcon/HORSEBOOK.svg",
    title: "H BOOK",
    link: "/",
  },
  {
    img: "../../images/casinoIcon/VIRTUAL.svg",
    title: "VIRTUAL",
    link: "/",
  },
  {
    // img: "../../images/casinoIcon/KABADDI.png",
    img: "../../images/casinoIcon/KABADDI.svg",
    title: "KABADDI",
    link: "/",
  },
  {
    img: "../../images/casinoIcon/HORSE-RACING.png",
    title: "H RACING",
    link: "/",
  },
];
