import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../common/Funcation";
import SimpleReactValidator from "simple-react-validator";
import { getApiLink, postApi } from "../service";
import { USER_API } from "../common/common";
import Cookies from "universal-cookie";
import {
  faCircleXmark,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const cookies = new Cookies();
export default function Login(this: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DD = useSelector((e: any) => e.domainDetails);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState({});
  const [isValidationWrong, setIsValidationWrong] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    validationCode: "",
  });
  const [confirmationCode, setConfirmationCode] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );
  const [isError, setIsError] = useState({ flag: false, msg: "" });

  useEffect(()=>{document.documentElement.style.setProperty('--bodyBackGround', `#111111`)},[])
  const goToHome = () => {
    navigate("/");
  };
  const isCaptcha = process.env.REACT_APP_CAPTCHA;
  const handelInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsValidationWrong(false);
    setFormData({ ...formData, [name]: value });
  };
  const handelSubmit = async (e: any) => {
    console.log("click :: ");

    e.preventDefault();
    if (
      Validator.current.allValid() &&
      (confirmationCode === parseInt(formData.validationCode) ||
        isCaptcha === "false")
    ) {
      setIsValidationWrong(false);
      const ipDetails = await getApiLink({
        api: "https://ipapi.co/json/?key=wfzfdfQ4cUsaTVURUkj2oF6L51Y4jNE0IM2yE0V2xMyMkxjTsr",
      });
      console.log("call ip:  ipDetails :", ipDetails);

      let data = {
        api: USER_API.LOGIN,
        value: {
          user_name: formData.username,
          password: formData.password,
          domain: window.location.hostname,
          // domain: "velki777.live",
          city: ipDetails?.data?.city,
          state: ipDetails?.data?.region,
          country: ipDetails?.data?.country_name,
          ISP: ipDetails?.data?.org,
        },
      };
      await postApi(data)
        .then(function (loginData) {
          if (loginData) {
            //  notifyMessage(loginData.data.message)

            console.log("::::::::;", loginData);
            let HeaderData = {
              ...loginData.data.data.roleAccess,
              user_name: loginData.data.data.user_name,
              remaining_balance: loginData.data.data?.balance,
              ...loginData.data.data,
            };
            //  dispatch({ type: 'HEADER_DETAILS', payload: HeaderData })
            cookies.set("skyTokenFront", loginData.data.data.token, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: "/",
            });
            dispatch({
              type: "AUTHENTICATION",
              payload: {
                isLogin: true,
                token: loginData.data.data.token,
                changePassword: loginData.data.data.newPassword,
              },
            });

            window.location.reload();
            // setOpenModal(false);
            if (loginData.data.data.newPassword === true) {
              navigate("change/password");
            }
          }
        })
        .catch((err) => {
          // notifyError(err.response.data.message);
          setIsError({
            flag: true,
            msg: err.response.data.message,
          });
          setIsValidationWrong(false);
          setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
          setFormData({ ...formData, validationCode: "" });
        });
    } else {
      console.log("else");

      Validator.current.showMessages();
      if (confirmationCode !== parseInt(formData.validationCode)) {
        setIsValidationWrong(true);
      }
      forceUpdate();
      setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
    }
  };

  const Validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className="login-title">
        <div className="back" onClick={goToHome}>
          <img
            src="../../images/extra-icon/angle-left-white.png"
            height={22}
          />
        </div>
        <div className="center">
          <h5>Login</h5>
        </div>
      </div>
      <div className="login-page-container">
        <div className="logo">
          <img src={getImageUrl(DD?.logo)} alt="" />
          {/* <img src="https://ballbet.live/source/icon/nav-logo.png" /> */}
        </div>
        {/* <form
          // className={`bg-white ${DD?.colorSchema}`}
          onSubmit={(e) => handelSubmit(e)}
        > */}
        <div className="form">
          <div className="form-line">
            <div className="form-label">Username</div>
            <div className="form-input">
              <input
                autoComplete="off"
                className="form-control"
                name="username"
                type="text"
                id="login-username"
                placeholder="username"
                value={formData?.username}
                onChange={handelInputChange}
              />
              {formData?.username && (
                <div className="form-action">
                  <span id="login-username-bool">
                    {/* <FontAwesomeIcon icon={faCircleXmark} /> */}
                  </span>
                  <span></span>
                </div>
              )}
            </div>
            <div className="form-action">
              <span id="login-username-bool">
                <i className="fa-solid fa-circle-xmark" aria-hidden="true"></i>
              </span>
              <span></span>
            </div>
            {Validator.current.message(
              "userName",
              formData.username,
              "required"
            ) ? (
              <span className="error" style={{ color: "red" }}>
                {Validator.current.message(
                  "userName",
                  formData.username,
                  "required"
                )}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="form-line">
            <div className="form-label">Password</div>
            <div className="form-input">
              <input
                autoComplete="off"
                name="password"
                className="form-control"
                type="password"
                id="login-password"
                placeholder="password"
                value={formData.password}
                onChange={handelInputChange}
              />
            </div>
            {/* <div className="form-action">
              {showPass ?
                <span id="login-password-eye" onClick={() => { setShowPass(false) }}><FontAwesomeIcon icon={faEye} /></span>
                :
                <span id="login-password-bool" onClick={() => { setShowPass(true) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
              }
            </div> */}
            {Validator.current.message(
              "password",
              formData.password,
              "required"
            ) ? (
              <span className="error" style={{ color: "red" }}>
                {Validator.current.message(
                  "password",
                  formData.password,
                  "required"
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {isError.flag && (
          <div style={{ paddingTop: "10px", color: "red", fontSize: "16px" }}>
            <span>{isError.msg}</span>
          </div>
        )}
        <div className="forgot-btn">
          <button>Forgot password?</button>
        </div>
        <div className="login-btn">
          <button
            id="login-submit"
            // className="login_btn"
            // type="submit"
            onClick={(e) => handelSubmit(e)}
          >
            Login
          </button>
        </div>
        {/* </form> */}
        <div className="dont-have-acc">
          <span>Do not have any account?</span>{" "}
          <span id="login-page-signupbtn">Sign up</span>
        </div>
      </div>
    </>
  );
}
